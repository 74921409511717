<template>
  <div class="d-flex flex-column align-items-center">
    <b-card class="bg-transparent w-50">
      <div ref="payment-message" id="payment-message" class="hidden"></div>

      <b-alert v-if="message" show :variant="alert_class">{{message}}</b-alert>
    </b-card>
  </div>
</template>

<script>
import messageParent from '@/core/services/messageParent';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';

export default {
  name: 'payment-return',
  data() {
    return {
      payment_intent: null,
      payment_intent_client_secret: null,
      message: null,
      alert_class: 'success'
    };
  },
  mounted() {
    let divScripts = document.getElementById('load-script');
    let newScript = document.createElement('script');
    newScript.src = 'https://js.stripe.com/v3/';
    divScripts.appendChild(newScript);
    
    this.payment_intent = this.$route.query.payment_intent;

    this.payment_intent_client_secret = this.$route.query.payment_intent_client_secret;

    const that = this;

    const interval_id = setInterval(function(){
      try {

        if (Stripe === undefined) {
          throw 'Stripe not defined';
        }

        that.checkStatus();

        clearInterval(interval_id);
      }
      catch (err) {
        console.error('stripe not loaded, retry...', err);
      }
    }, 1000);

  },
  methods: {
    // Fetches the payment intent status after payment submission
    async checkStatus() {

      const stripe_pk = getKeyValue('stripe.pk', null);

      if (!stripe_pk || stripe_pk.length < 16) {
        console.error('invalid stripe_pk', stripe_pk);
        return;
      }
      
      const stripe = Stripe(stripe_pk);

      let loader = this.$loading.show();

      const { paymentIntent } = await stripe.retrievePaymentIntent(this.payment_intent_client_secret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!", 'success');

          this.redirectIfSet();

          break;
        case "processing":
          this.showMessage("Your payment is processing.", 'danger');
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.", 'danger');
          break;
        default:
          this.showMessage("Something went wrong.", 'danger');
          break;
      }

      loader & loader.hide();
    },

    redirectIfSet() {
      const url = getKeyValue('stripe.redirect_url_after_return');

      if (!url) {
        return;
      }

      setTimeout(function(){
        window.location = url;

        messageParent(JSON.stringify({ redirect: url }));

      }, 2000);
    },

    showMessage(message, alert_class) {
      this.message = message;
      this.alert_class = alert_class;
      
    },
  },
  computed: {
    
  }
};
</script>
